import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
  Tooltip,
} from "@mui/material";
import { BASEURL, headers, checktoken } from "../../utils/helper";
import ButtonSpinner from "../../components/common/ButtonSpinner";
import CustomTooltip from "../../components/common/CustomTooltip";
import { addMessage } from "../../redux/slices/MessageSlice";
import { useDispatch, useSelector } from "react-redux";
import Toaster from "../../components/common/Toaster";
import { useNavigate } from "react-router-dom";
import { icons } from "../../assets/js/icons";
import { Fragment, useEffect, useRef, useState } from "react";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import axios from "axios";
import { IsTrialReq } from "../../redux/slices/IsTrialSlice";

const plan = [
  {
    name: "Execs Basic",
    price: { monthly: 31.0, halfYearly: 29.0 },
    ids: {
      monthly: "668f948fdcee8752f65c26cf",
      halfYearly: "668f948fdcee8752f65c26d0",
    },
    plankey: { monthly: "basic_monthly", halfYearly: "basic_semianual" },
    description: {
      monthly: "Monthly: $31/ month",
      semi: "Semi-Annual: $29/ month (charged $174 for 6 months)",
    },
    trial: "5 days's free trial",
    features: [
      "Chatgpt4-o",
      "Translator",
      "Rephraser",
      "Transcriber",
      "Projects pool",
    ],
  },
  {
    name: "Pro Individual Modules",
    price: { monthly: 0, halfYearly: 0 },
    description: {
      monthly: "",
      semi: "",
    },
    features: [
      {
        name: "Article writer",
        id: "ar",
        price: {
          monthly: 3.0,
          halfYearly: 2.0,
          ar_monthly: "price_1PfFi0KN9tSy5x3qpDOeqgrl",
          ar_semianual: "price_1PfFkMKN9tSy5x3q50YtX2GX",
          plankey: { monthly: "ar_monthly", halfYearly: "ar_semianual" },
        },
        type: "basic",
      },
      {
        name: "Email Generator",
        id: "eg",
        price: {
          monthly: 2.0,
          halfYearly: 1.0,
          email_monthly: "price_1PfFmxKN9tSy5x3qSqqApBwY",
          email_semianual: "price_1PfFqpKN9tSy5x3qhvk8vu9H",
          plankey: { monthly: "email_monthly", halfYearly: "email_semianual" },
        },
        type: "basic",
      },
      {
        name: "Doc Reader",
        id: "dr",
        price: {
          monthly: 3.0,
          halfYearly: 2.0,
          dr_monthly: "price_1PfFwIKN9tSy5x3q6sDaWfat",
          dr_semianual: "price_1PfFzmKN9tSy5x3q4woZ9DKE",
          plankey: { monthly: "dr_monthly", halfYearly: "dr_semianual" },
        },
        type: "basic",
      },
      {
        name: "HR Upload",
        id: "hr",
        price: {
          monthly: 5.0,
          halfYearly: 3.0,
          hr_monthly: "price_1PfG1ZKN9tSy5x3qEc6TRaA6",
          hr_semianual: "price_1PfG3eKN9tSy5x3qWvEE2yWm",
          plankey: { monthly: "hr_monthly", halfYearly: "hr_semianual" },
        },
        type: "basic",
      },
      {
        name: "Social Media",
        id: "sm",
        price: {
          monthly: 2.0,
          halfYearly: 1.0,
          sm_monthly: "price_1PfG7tKN9tSy5x3qxPF4VhEw",
          sm_semianual: "price_1PfG9TKN9tSy5x3qpYqsS6dT",
          plankey: { monthly: "sm_monthly", halfYearly: "sm_semianual" },
        },
        type: "basic",
      },
      {
        name: "Image Generator",
        id: "ig",
        price: {
          monthly: 4.0,
          halfYearly: 3.0,
          ig_monthly: "price_1PfGHjKN9tSy5x3q33Lfhr8L",
          ig_semianual: "price_1PfGJsKN9tSy5x3qxVC5lDvG",
          plankey: { monthly: "ig_monthly", halfYearly: "ig_semianual" },
        },
        type: "basic",
      },
    ],
  },
  {
    name: "Enterprise Plan",
    price: { monthly: 46.0, halfYearly: 43.0 },
    ids: {
      monthly: "668f948fdcee8752f65c26d1",
      halfYearly: "668f948fdcee8752f65c26d2",
    },
    plankey: { monthly: "pro_monthly", halfYearly: "pro_semianual" },
    description: {
      monthly: "Monthly: $46/ month",
      semi: "Semi-Annual: $43/ month (charged $258 for 6 months)",
    },
    trial: "5 days's free trial",
    features: [
      "Chatgpt 4-o",
      "Article writer",
      "Email Generator",
      "Translator",
      "Rephraser",
      "Transcriber",
      "Doc reader (upload docs or weblink  in any language, ask questions or summary at any language)",
      "HR (Resume Upload, and search)",
      "Social Media Content Generator (Caption and hashtag)",
      "Image Generator",
      "Text to Voice & Video",
      "Projects pool",
      "Business insights (Management Education)",
    ],
  },
  {
    name: "Text to Voice & Video",
    price: { monthly: 20.0, halfYearly: 16.5 },
    ids: {
      monthly: "66b47dc32456c10e123ef858",
      halfYearly: "66b47e282456c10e123ef859",
    },
    plankey: { monthly: "ttv_monthly", halfYearly: "ttv_semianual" },
    description: {
      monthly: "Monthly: $20/ month",
      semi: "Semi-Annual: $16.5/ month (charged $99 for 6 months)",
    },
    features: ["Text to Voice & Video"],
  },
];

function PricingPage() {
  const cookie = new Cookies();
  const [tab, setTab] = useState(0);

  const dispatch = useDispatch();
  const { data: istrial } = useSelector((state) => state.IsTrialSlice);
  //   console.log(istrial);

  useEffect(() => {
    if (cookie.get("auth")) {
      dispatch(IsTrialReq());
    }
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>AI Assistant | Top Executives</title>
        <meta property="og:title" content={"AI Assistant"} />: for SEO
      </Helmet>
      <Box id="Subscriptions" className="bg-gray-100">
        <section className="py-10  border-t-[1px] border-border">
          <Container className="" maxWidth="xl">
            <Box>
              <Typography
                variant="h2"
                className="ff-jost capitalize font-semibold text-5xl  text-center"
              >
                Membership
              </Typography>
              <Typography
                variant="h6"
                className="font-bold py-2 text-center  w-full md:w-5/6 mx-auto ff-lato"
              >
                “If you don't have credit card send a message in your own
                language to the Telegram group of “Top-executives” to be guided
                on how to pay for your subscription
              </Typography>
            </Box>
            <Box className="flex justify-center py-7">
              <Box className="shadow-lg px-[20px] py-[10px] rounded-md">
                <Button
                  className={`tab-btn hover:text-[#fff] hover:bg-primary-disabled px-3 me-1 rounded-full transition-custom${
                    tab === 0 ? " active" : ""
                  }`}
                  onClick={() => {
                    setTab(0);
                  }}
                >
                  monthly
                </Button>
                <CustomTooltip
                  title="6 Monthly"
                  placement="top"
                  arrow
                  content={
                    <Button
                      className={`tab-btn hover:text-[#fff] hover:bg-primary-disabled px-3 me-1 rounded-full transition-custom${
                        tab === 1 ? " active" : ""
                      }`}
                      onClick={() => {
                        setTab(1);
                      }}
                    >
                      semi annual
                    </Button>
                  }
                />
              </Box>
            </Box>
            <Box className="w-full">
              <Box
                role="tabpanel"
                hidden={tab !== 0}
                id="detail-tabpanel"
                aria-labelledby="detail-tab"
              >
                <Grid
                  container
                  className="justify-center"
                  spacing={2}

                  // direction="row"
                  // justifyContent="center"
                  // className="bred w-full"
                >
                  {plan.map((item, i) => (
                    <Grid key={`item-${i}`} item xs={12} md={6} lg={3}>
                      <div className="flex justify-center min-h-full">
                        <PriceCard
                          name={item?.name}
                          price={item?.price?.monthly}
                          id={item?.ids?.monthly}
                          trial={item?.trial}
                          features={item?.features}
                          plankey={item?.plankey?.monthly}
                          description={item?.description?.monthly}
                          tab={0}
                          istrial={istrial}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Box
                role="tabpanel"
                hidden={tab !== 1}
                id="detail-tabpanel"
                aria-labelledby="detail-tab"
              >
                <Grid container className="justify-center" spacing={2}>
                  {plan.map((item, i) => (
                    <Grid key={`item-${i}`} item xs={12} md={6} lg={3}>
                      {/* <PriceCard
                        name={item?.name}
                        price={item?.price?.halfYearly}
                        id={item?.ids?.halfYearly}
                        trial={item?.trial}
                        features={item?.features}
                        plankey={item?.plankey?.halfYearly}
                        tab={1}
                        description={item?.description?.semi}
                      /> */}
                      <div className="flex justify-center min-h-full">
                        <PriceCard
                          name={item?.name}
                          price={item?.price?.halfYearly}
                          id={item?.ids?.halfYearly}
                          trial={item?.trial}
                          features={item?.features}
                          plankey={item?.plankey?.halfYearly}
                          tab={1}
                          description={item?.description?.semi}
                        />
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Container>
        </section>
      </Box>
    </Fragment>
  );
}

const PriceCard = ({
  name,
  price,
  trial,
  features,
  id,
  plankey,
  description,
  tab,
  istrial,
}) => {
  const dispatch = useDispatch();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const isLogin = Boolean(cookies.get("auth"));
  console.log("isLogin.../", isLogin);
  const [spinner, setSpinner] = useState(false);
  const { currentplan } = useSelector((state) => state.AuthSlice);
  const [addedFeature, setAddedFeature] = useState([]);
  const [enterpriseLineItems, setEnterpriseLineItems] = useState([]);
  const [priceVal, setPriceVal] = useState(price);
  const [currentPlanPackages, setCurrentPlanPackages] = useState([]);
  const [currentPlansStatus, setCurrentPlansStatus] = useState([]);
  // console.log("currentplan.../", currentplan);
  // console.log("currentPlansStatus.../", currentPlansStatus);
  // console.log("currentPlanPackages.../", currentPlanPackages);

  useEffect(() => {
    if (currentplan) {
      const plans = [];
      let statuses = {};
      currentplan?.map((pack) => {
        statuses = { ...statuses, [pack?.plankey]: pack?.status };
        plans.push(pack?.plankey);
      });
      setCurrentPlansStatus(statuses);
      setCurrentPlanPackages(plans);
    }
  }, [currentplan]);

  // useEffect(() => {
  //   console.log("tab.../", tab);
  //   setAddedFeature([]);
  //   setEnterpriseLineItems([]);
  //   setPriceVal(price);
  // }, [tab]);

  const epClick = (price, feature, months, priceKey) => {
    console.log("epClick priceKey.../", priceKey);
    if (!priceKey) return 0;
    const added = addedFeature.includes(feature);
    const lineItems = enterpriseLineItems.includes(priceKey);
    if (!lineItems) {
      // console.log("lineItems added.../")
      setEnterpriseLineItems((o) => [priceKey, ...o]);
    } else {
      const lineFilter = enterpriseLineItems.filter(
        (item) => item !== priceKey
      );
      // console.log("lineItems removed.../",lineFilter)

      setEnterpriseLineItems(lineFilter);
    }
    if (!added) {
      setAddedFeature((o) => [feature, ...o]);
      setPriceVal((o) => o + price * months);
    } else {
      const arrFilter = addedFeature.filter((item) => item !== feature);
      setAddedFeature(arrFilter);
      setPriceVal((o) => o - price * months);
    }
  };

  // if (plankey === "basic_monthly") {
  // }
  // console.log("currentPlansStatus.../", currentPlansStatus);
  // console.log("plankey.../", plankey);
  // console.log("currentPlansStatus[plankey]", currentPlansStatus[plankey]);
  const EnterprisePayment = () => {
    const lineItems = [];
    // console.log("enterpriseLineItems.../",enterpriseLineItems)
    enterpriseLineItems.map((item) =>
      lineItems.push({ price: item, quantity: 1 })
    );
    const payload = { lineItems };
    // console.log("enterprise payload.../", payload);

    setSpinner(true);
    axios
      .post(`${BASEURL}/admin/create/additional/payment`, payload, {
        headers: headers(),
      })
      .then((res) => {
        setSpinner(false);
        if (res.data.success === true) {
          dispatch(addMessage({ msg: "You all are set!" }));
          window.location.replace(res.data.data.url);
        }
      })
      .catch((err) => {
        setSpinner(false);
        checktoken(err);
        if (err?.response?.data?.success === false) {
          toast.error(err.response.data.error);
        }
      });
  };

  const CreatePaymentLink = (id) => {
    setSpinner(true);
    axios
      .post(
        `${BASEURL}/admin/create/payment`,
        { id: id },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        setSpinner(false);
        if (res.data.success === true) {
          dispatch(addMessage({ msg: "You all are set!" }));
          window.location.replace(res.data.data.url);
        }
      })
      .catch((err) => {
        setSpinner(false);
        checktoken(err);
        if (err?.response?.data?.success === false) {
          toast.error(err.response.data.error);
        }
      });
  };

  const upgradePlan = (id) => {
    setSpinner(true);
    axios
      .post(
        `${BASEURL}/admin/update/payment`,
        { id: id },
        {
          headers: headers(),
        }
      )
      .then((res) => {
        setSpinner(false);
        if (res.data.success === true) {
          dispatch(addMessage({ msg: "You all are set!" }));
          navigate("/user/chat/outside");
        }
      })
      .catch((err) => {
        checktoken(err);
        setSpinner(false);
        if (err?.response?.data?.success === false) {
          toast.error(err.response.data.error);
        }
      });
  };

  const buttonRef = useRef(null);

  const handleMouseEnter = (plankey) => {
    if (buttonRef.current) {
      if (currentPlansStatus[plankey] === "trialing")
        buttonRef.current.textContent = "Buy Now";
    }
  };

  const handleMouseLeave = (plankey) => {
    if (buttonRef.current) {
      // if (currentplan?.status === "trialing")
      if (currentPlansStatus[plankey] === "trialing")
        buttonRef.current.textContent = "Active Plan";
    }
  };

  return (
    <Card
      className={`border-[2px] ${
        (name === "Pro Individual Modules" ||
          name === "Text to Voice & Video") &&
        "w-[320px]"
      } max-w-[320px] min-h-full shadow-md hover:shadow-lg border-border hover:border-primary-light bg-primary-light transition-custom rounded-lg`}
    >
      <Toaster />
      <CardContent className="rounded-br-[100px] bg-white h-full">
        <Stack
          spacing={3}
          className={
            name === "Text to Voice & Video" ? "h-[300px]" : "h-[300px]"
          }
        >
          <Typography
            variant="h6"
            className="text-center ff-jost text-2xl font-bold "
          >
            {name}
          </Typography>
          <Typography
            variant="body1"
            className="text-center text-4xl font-semibold  ff-lato"
          >
            <span className="font-normal">$</span>
            {priceVal?.toFixed(2)}
            {
              <small className="fw-bold" style={{ fontSize: 14 }}>
                {name === "Pro Individual Modules" && tab === 1
                  ? "/6 Months"
                  : "/Monthly"}
              </small>
            }
          </Typography>
          <Typography
            variant="body2"
            style={{ minHeight: "65px" }}
            className="text-center font-medium text-gray-500 ff-lato"
          >
            <Typography
              variant="body2"
              component="span"
              className="text-center ff-lato  text-base font-semibold mb-2 d-block"
            >
              {description}{" "}
            </Typography>
            <span className="d-block">
              {(name !== "Pro Individual Modules" ||
                name !== "Text to Voice & Video") && (
                <Typography
                  variant="body2"
                  component="span"
                  className="text-center ff-lato  text-base font-semibold"
                >
                  {name !== "Pro Individual Modules" &&
                    name !== "Text to Voice & Video" &&
                    "Free trial :"}
                  {"  "}
                </Typography>
              )}
              {trial}
            </span>
            <div className="">
              {/* {name !== "Text to Voice & Video" && ( */}
              {true && (
                <>
                  <Typography
                    variant="body2"
                    // component="span"
                    className={`text-center ${
                      name === "Pro Individual Modules"
                        ? "min-h-[75px]"
                        : "min-h-[75px] mt-2"
                    }  ff-lato  text-base font-normal  d-block`}
                  >
                    {(price === 31 || price === 29) &&
                      name !== "Pro Individual Modules" &&
                      "Total of 300 request per months for total of modules, not transferable to next month"}
                    {name === "Pro Individual Modules" &&
                      "You can add any module from the list to your Active 'Execs Basic' Plan, or if you need all Pro modules, you may choose Enterprise plan"}
                    {name === "Text to Voice & Video" && tab === 0
                      ? ` Total of 80 minutes per month`
                      : ` Total of 480 minutes for 6 months`}
                  </Typography>
                </>
              )}
            </div>
            <div className="">
              {name !== "Text to Voice & Video" && (
                <Typography
                  variant="body2"
                  // component="span"
                  className="text-center min-h-[75px] ff-lato  text-base font-normal mt-2 d-block"
                >
                  {name === "Pro Individual Modules" &&
                    "Pro modules will expire when basic plan  expires"}
                </Typography>
              )}
            </div>
          </Typography>
        </Stack>
        <Box className="my-4">
          {isLogin &&
          plankey &&
          (currentPlansStatus[plankey] === "active" ||
            currentPlansStatus[plankey] === "trialing") ? (
            <Button
              size="large"
              // bg-secondary-main
              className={`w-full rounded-full 
                bg-[#ffd700]
                text-black !font-medium  ${
                  currentPlansStatus[plankey] === "trialing"
                    ? "hover:bg-primary-disabled hover:text-white"
                    : "hover:bg-secondary-main hover:text-white"
                }
                ${
                  // currentPlansStatus[plankey] === "active" &&
                  "hover:!text-white"
                }
                `}
              onClick={() => {
                if (currentPlansStatus[plankey] === "trialing")
                  CreatePaymentLink(id);
              }}
              ref={buttonRef}
              onMouseEnter={() => handleMouseEnter(plankey)}
              onMouseLeave={() => handleMouseLeave(plankey)}
            >
              Active Plan
            </Button>
          ) : (
            <CustomTooltip
              title={
                (istrial?.isTrial || !cookies.get("auth")) &&
                "Start your Free Trial"
              }
              placement={"top"}
              arrow
              content={
                <Button
                  size="large"
                  onClick={() => {
                    console.log("currentPlanPackages.../", currentPlanPackages);
                    if (!isLogin) {
                      return navigate(`/login?priceid=${id}`);
                    } else if (
                      name === "Execs Basic" &&
                      (currentPlanPackages.includes("pro_monthly") ||
                        currentPlanPackages.includes("pro_anual"))
                    )
                      return;
                    if (cookies.get("auth") && currentplan[0] === null) {
                      if (name === "Pro Individual Modules") {
                        // EnterprisePayment();
                        console.log("cookies.get(auth)", cookies.get("auth"));
                        console.log("currentplan[0]===null");
                        CreatePaymentLink(id);
                      } else {
                        CreatePaymentLink(id);
                      }
                    } else if (
                      cookies.get("auth") &&
                      currentplan &&
                      (currentplan[0]?.status === "trialing" ||
                        currentplan[0]?.status === "canceled")
                    ) {
                      if (name === "Pro Individual Modules") {
                        CreatePaymentLink(id);
                        // EnterprisePayment();
                      } else {
                        CreatePaymentLink(id);
                      }
                    } else if (
                      currentplan[0]?.status === "active" &&
                      name !== "Pro Individual Modules"
                    ) {
                      CreatePaymentLink(id);
                    } else if (
                      currentplan?.length > 0 &&
                      currentplan?.[0]?.status === "active" &&
                      name === "Pro Individual Modules"
                    ) {
                      // EnterprisePayment();
                      CreatePaymentLink(id);
                    } else {
                      navigate(`/login?priceid=${id}`);
                    }
                  }}
                  disabled={Number(priceVal) < 1 ? true : false}
                  //
                  className={`w-full rounded-full ${
                    Number(priceVal) > 0
                      ? "bg-primary-light hover:bg-primary-main text-[#537a8b]"
                      : "bg-primary-disabled"
                  } text-white font-medium`}
                >
                  {isLogin &&
                  currentplan?.[0]?.status === "active" &&
                  name !== "Pro Individual Modules"
                    ? "Upgrade"
                    : istrial?.isTrial === true && currentplan[0] === null
                    ? "Buy Now"
                    : isLogin
                    ? "Get started"
                    : "Login"}{" "}
                  {spinner === true && <ButtonSpinner />}
                </Button>
              }
            />
          )}
        </Box>
        <div className="">
          <Stack spacing={1}>
            {features &&
              features.map((item, i) => {
                if (
                  ((currentPlanPackages.includes("pro_monthly") ||
                    currentPlanPackages.includes("pro_semianual")) &&
                    item.type !== "basic" &&
                    name === "Pro Individual Modules") ||
                  ((true ||
                    // currentPlansStatus["pro_monthly"]
                    currentPlansStatus["pro_semianual"]) &&
                    name === "Pro Individual Modules") ||
                  name === "Execs Basic" ||
                  name === "Enterprise Plan" ||
                  name === "Text to Voice & Video"
                ) {
                  return (
                    <Box
                      key={`item-${i}`}
                      className="flex w-full gap-1 text-gray-500"
                    >
                      {/* {name !== "Enterprise" && ( */}
                      <icons.MuiTaskAlt
                        className={`text-lg ${
                          name === "Pro Individual Modules" &&
                          addedFeature.includes(item.name) &&
                          "text-green-500"
                        }
                      ${
                        ((currentPlanPackages.includes(
                          item?.price?.plankey?.monthly
                        ) &&
                          currentPlanPackages.includes("basic_monthly") &&
                          tab === 0) ||
                          (currentPlanPackages.includes(
                            item?.price?.plankey?.halfYearly
                          ) &&
                            currentPlanPackages.includes("basic_semianual") &&
                            tab === 1)) &&
                        " text-green-500"
                      }
                      
                      `}
                      />
                      {/* )} */}
                      {/* {name !== "Enterprise" ? ( */}
                      <Typography
                        onClick={() => {
                          if (
                            name === "Pro Individual Modules" &&
                            (currentPlanPackages.includes("basic_monthly") ||
                              currentPlanPackages.includes("basic_semianual"))
                          ) {
                            if (tab === 0 && currentplan?.status !== "trialing")
                              epClick(
                                item.price.monthly,
                                item.name,
                                1,
                                item.id === "ar" &&
                                  !currentPlanPackages.includes("ar_monthly") &&
                                  !currentPlanPackages.includes("ar_semianual")
                                  ? item.price.ar_monthly
                                  : item.id === "eg" &&
                                    !currentPlanPackages.includes(
                                      "email_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "email_semianual"
                                    )
                                  ? item.price.email_monthly
                                  : item.id === "dr" &&
                                    !currentPlanPackages.includes(
                                      "dr_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "dr_semianual"
                                    )
                                  ? item.price.dr_monthly
                                  : item.id === "hr" &&
                                    !currentPlanPackages.includes(
                                      "hr_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "hr_semianual"
                                    )
                                  ? item.price.hr_monthly
                                  : item.id === "sm" &&
                                    !currentPlanPackages.includes(
                                      "sm_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "sm_semianual"
                                    )
                                  ? item.price.sm_monthly
                                  : item.id === "ig" &&
                                    !currentPlanPackages.includes(
                                      "ig_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "ig_semianual"
                                    )
                                  ? item.price.ig_monthly
                                  : null
                              );
                            if (tab === 1 && currentplan?.status !== "trialing")
                              epClick(
                                item.price.halfYearly,
                                item.name,
                                6,
                                item.id === "ar" &&
                                  !currentPlanPackages.includes("ar_monthly") &&
                                  !currentPlanPackages.includes("ar_semianual")
                                  ? item.price.ar_semianual
                                  : item.id === "eg" &&
                                    !currentPlanPackages.includes(
                                      "email_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "email_semianual"
                                    )
                                  ? item.price.email_semianual
                                  : item.id === "dr" &&
                                    !currentPlanPackages.includes(
                                      "dr_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "dr_semianual"
                                    )
                                  ? item.price.dr_semianual
                                  : item.id === "hr" &&
                                    !currentPlanPackages.includes(
                                      "hr_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "hr_semianual"
                                    )
                                  ? item.price.hr_semianual
                                  : item.id === "sm" &&
                                    !currentPlanPackages.includes(
                                      "sm_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "sm_semianual"
                                    )
                                  ? item.price.sm_semianual
                                  : item.id === "ig" &&
                                    !currentPlanPackages.includes(
                                      "ig_monthly"
                                    ) &&
                                    !currentPlanPackages.includes(
                                      "ig_semianual"
                                    )
                                  ? item.price.ig_semianual
                                  : null
                              );
                          }
                        }}
                        variant="body2"
                        className={`font-medium ff-lato ${
                          name === "Pro Individual Modules" && "cursor-pointer"
                        }`}
                      >
                        {name === "Pro Individual Modules" ? (
                          <>
                            <div
                              className={`flex  ${
                                ((currentPlanPackages.includes(
                                  item?.price?.plankey?.monthly
                                ) &&
                                  currentPlanPackages.includes(
                                    "basic_monthly"
                                  ) &&
                                  tab === 0) ||
                                  (currentPlanPackages.includes(
                                    item?.price?.plankey?.halfYearly
                                  ) &&
                                    currentPlanPackages.includes(
                                      "basic_semianual"
                                    ) &&
                                    tab === 1)) &&
                                " text-green-500"
                              } w-[250px] gap-3 justify-between `}
                            >
                              <p>{item.name}</p>
                              <p>
                                {tab === 0 &&
                                  "$ " + item.price.monthly + "/month"}
                                {tab === 1 &&
                                  "$ " + item.price.halfYearly + "/month"}
                              </p>
                            </div>
                          </>
                        ) : (
                          <>
                            {item === "Text to Voice & Video" ? (
                              <>
                                {tab === 0
                                  ? item + "/40 minutes per month"
                                  : item + "/50 minutes per month"}
                              </>
                            ) : (
                              <>{item}</>
                            )}
                          </>
                        )}
                      </Typography>
                    </Box>
                  );
                }
              })}
          </Stack>
        </div>
      </CardContent>
    </Card>
  );
};

export default PricingPage;
